import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { 
    Alert,
    Button, 
    Form,
    FloatingLabel,
    Stack,
 } from 'react-bootstrap';
import * as API from '../data/API';
import * as Config from '../data/Config';
import axios from 'axios';



export default function Login ( props ){
    const onLogin = (val, val2) => props.onLogin(val, val2)
    const RecoveryPasswordRequest = (val) => props.RecoveryPasswordRequest(val)
    
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search);
    const recoveryToken = queryParams.get('r')
    const onsignup = queryParams.has('onsignup') 
    const webview = queryParams.has('webview')
    const authtoken = queryParams.get('a')

    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState( queryParams.get('u') || props.username )
    const [password, setPassword] = useState( '' )
    
    
    // signup
    const [signupUsername, setSignupUsername] = useState( '' )
    const [type, setType] = useState( 'person' )
    const [name, setName] = useState( '' )
    const [fio, setFio] = useState( '' )
    const [position, setPosition] = useState( '' )
    const [signupPassword, setSignupPassword] = useState( '' )
    const [signupPasswordRepeat, setSignupPasswordRepeat] = useState( '' )
    const [signupError, setSignupError] = useState( '' )

    useEffect(()=>{
        setSignupError('')
    },[signupUsername,position, fio, name, type,signupPassword,signupPasswordRepeat])
    
    
    
    const [recoveryPassword, setRecoveryPassword] = useState( '' )
    const [recoveryPasswordRepeat, setRecoveryPasswordRepeat] = useState( '' )
    const [isPasswordStrong, setIsPasswordStrong] = useState( true )
    const [isPasswordEqual, setIsPasswordEqual] = useState( true )

    useEffect(()=>{
        if (onsignup||webview) {
            CheckOnSignUpAuthToken()
        }

        setIsPasswordStrong( recoveryPassword.length==0 || checkPasswordStength(recoveryPassword))
        setIsPasswordEqual( recoveryPassword.length==0 || recoveryPasswordRepeat.length==0 || recoveryPassword==recoveryPasswordRepeat )
    },[recoveryPassword, recoveryPasswordRepeat])


    // const [mode, setMode] = useState( recoveryToken ? 'recovery' : 'login' )
    
    const changePassword = () => {
        setIsLoading(true)
        API.Post({ method:'changepassword'}, {password_recovery_hash: recoveryToken, password:recoveryPassword})
        .then(res => {
            if ( res.data.result ){
                alert('Пароль успешно изменен. Вы можете войти с новым паролем')
                // setMode('login')
            } else {
                alert('Пароль не изменен. Проверьте актуальность ссылки на восстановление и активность учетной записи.')
            }
        })
        .catch( err => alert(err))
        .finally(()=>setIsLoading(false))
    }
    
    function checkPasswordStength(str) {
        return /^[a-zA-Z0-9!"№#$%:,.;()_+]{6,16}$/.test(str) && /[a-z]/.test(str) && /[A-Z]/.test(str) && /\d/.test(str);
    }

    const CheckOnSignUpAuthToken = ( ) => {
        setIsLoading(true)
        API.Post({ method:'checkonsignupauthtoken'}, {username, authtoken})
        .then(res => {
            console.log( {username, authtoken} )
            console.log( res.data )
            if (res.data.result) {
                onLogin( authtoken, username )
            }
        })
        .finally(()=>setIsLoading(false))
    }


    const Login = () => {
        if (!username||!password){
            alert("Не указаны e-mail или пароль")
        } else {
            setIsLoading(true)
            API.Post({ method:'login'}, {username, password})
            .then(res => {
                console.log( res.data )
                if ( res.data.result ){
                    onLogin(res.data.authtoken, username)
                } else {
                    alert("Неверный e-mail или пароль")
                }
            })
            .finally(()=>setIsLoading(false))
        }
    }

    const Signup = () => {

        const isEmail = ( val ) => {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(val).toLowerCase());
        }

        function md5(inputString) {
            var hc="0123456789abcdef";
            function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
            function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
            function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
            function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
            function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
            function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
            function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
            function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
            function sb(x) {
                var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
                for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
                blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
            }
            var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
            for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
                a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
                b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
                c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
                d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
                a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
                b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
                c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
                d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
                a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
                b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
                c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
                d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
                a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
                b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
                c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
                d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
                a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
                b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
                c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
                d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
                a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
                b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
            }
            return rh(a)+rh(b)+rh(c)+rh(d);
        }

        const getCompactDeviceInfo = () => {
            const userAgent = navigator.userAgent;
            const platform = navigator.platform;
            const isMobile = /Mobi|Android|iPhone/i.test(userAgent) ? "Mobile" : "Desktop";
            const browserMatch = userAgent.match(
              /(Chrome|Firefox|Safari|Opera|Edg|MSIE|Trident)/i
            );
            const browser = browserMatch ? browserMatch[0] : "UnknownBrowser";
            const osMatch = userAgent.match(
              /(Windows|Macintosh|Linux|Android|iPhone|iPad)/i
            );
            const os = osMatch ? osMatch[0] : platform || "UnknownOS";
            return `${isMobile} ${os} ${browser}`;
        }
        
        if ( 
            ( type=='person' && (!signupUsername || !fio || !signupPassword || !signupPasswordRepeat) ) ||
            ( type=='company' && (!signupUsername || !name || !position || !fio || !signupPassword || !signupPasswordRepeat) )
        ){
            setSignupError("Заполните все поля формы")
        } else if (!isEmail(signupUsername)) {
            setSignupError("Укажите корректный эл. адрес")
        } else if (!checkPasswordStength(signupPassword)) {
            setSignupError("Пароль слабоват, используйте символы в разном регистре и цифры, не менн 6 символов")
        } else if (signupPassword!=signupPasswordRepeat) {
            setSignupError("Повтор пароля не совпадает")
        } else {
            setIsLoading(true)
            axios({
                method: 'post',
                baseURL: 'https://priemka-pro.ru/api/v6/',
                params: {method: 'createaccount'},
                data: {
                    type,
                    name,
                    fio,
                    position,
                    username: signupUsername,
                    password_hash: md5(signupPassword),
                    device: getCompactDeviceInfo(),
                    app: 'my'
                },
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then( res => {
                console.log( res.data )
                if ( res.data.result ){
                    onLogin(res.data.authtoken, username)
                    navigate('/forms')
                } else {
                    setSignupError( res.data.error || "Что-то пошло не так. Повторите попытку позже.")
                }
            })
            .catch(err => {
                setSignupError(err)
            })
            .finally(()=>setIsLoading(false))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log('do validate')
        }
    }

    

    const LoginForm = () => (
        <form>
            <Stack gap={3}>
                <FloatingLabel label="E-mail">
                    <Form.Control 
                        type="email" 
                        placeholder="Email"
                        value={username}
                        onChange={(e)=> setUsername(e.target.value) }
                    />
                </FloatingLabel>
                <FloatingLabel label="Пароль">
                    <Form.Control 
                        type="password" 
                        placeholder="Пароль"
                        value={password}
                        onChange={(e)=> setPassword(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Link className="link text-decoration-none ms-2" to="/recovery">Забыли пароль?</Link>
                <Button
                    variant="primary"
                    onClick={ Login }
                    disabled={isLoading}
                    className='w-50'
                >
                    Войти
                </Button>
            </div>
        </form>
    )

    const SignupForm = () => (
        Config.FeatureToggles.signupAvaliable &&
        <div>
            <h4 class="text-center my-3">Регистрация нового аккаунта</h4>
            <form>
                <Stack gap={3}>
                    <div className="mt-3">
                        <Form.Check // prettier-ignore
                            type='radio'
                            id='type-person'
                            label='Работаю один'
                            checked={ type == 'person' }
                            onChange={ ()=>setType('person')}
                        />
                        <Form.Check // prettier-ignore
                            type='radio'
                            id='type-company'
                            label='Представляю компанию'
                            checked={ type == 'company' }
                            onChange={ ()=>setType('company')}
                        />
                    </div>
                    {
                        type == 'company' &&
                        <FloatingLabel label="Название компании">
                            <Form.Control 
                                placeholder=""
                                value={name}
                                onChange={(e)=> setName(e.target.value) }
                            />
                        </FloatingLabel>
                    }
                    <FloatingLabel label="Имя и фамилия">
                        <Form.Control 
                            placeholder=""
                            value={fio}
                            onChange={(e)=> setFio(e.target.value) }
                            />
                    </FloatingLabel>

                    {
                        type == 'company' &&
                        <FloatingLabel label="Ваша позиция в компании">
                            <Form.Control 
                                placeholder=""
                                value={position}
                                onChange={(e)=> setPosition(e.target.value) }
                            />
                        </FloatingLabel>
                    }

                    <FloatingLabel label="Эл. почта">
                        <Form.Control 
                            type="email" 
                            placeholder=""

                            value={signupUsername}
                            onChange={(e)=> setSignupUsername(e.target.value) }
                        />
                    </FloatingLabel>
                    <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <FloatingLabel label="Придумайте пароль" className="w-50 me-2">
                            <Form.Control 
                                type="password" 
                                placeholder="Пароль"
                                value={signupPassword}
                                onChange={(e)=> setSignupPassword(e.target.value) }
                                onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                            />
                        </FloatingLabel>
                        <FloatingLabel label="И повторите пароль"  className="w-50 ms-2">
                            <Form.Control 
                                type="password" 
                                placeholder="Пароль"
                                value={signupPasswordRepeat}
                                onChange={(e)=> setSignupPasswordRepeat(e.target.value) }
                                onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                            />
                        </FloatingLabel>
                    </div>
                    
                    <div>
                        {
                            signupError &&
                            <Alert key='signup-error' variant='danger'>
                                {signupError}
                            </Alert>
                        }

                        <Button
                            variant="primary"
                            onClick={ Signup }
                            disabled={isLoading}
                            className='w-100 py-2'
                        >
                            Зарегистрироваться
                        </Button>
                    </div>
                </Stack>
            </form>
        </div>
    )

    const RecoveryRequest = () => (
        <form>
            <Stack gap={3}>
                <FloatingLabel label="E-mail">
                    <Form.Control 
                        type="email" 
                        placeholder="Email"
                        value={username}
                        onChange={(e)=> setUsername(e.target.value) }
                    />
                </FloatingLabel>
            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Link className="link text-secondary text-decoration-none ms-2" to="/">&larr; Назад</Link>
                <Button
                    variant="primary"
                    onClick={()=>RecoveryPasswordRequest(username) }
                    disabled={isLoading}
                    className='w-50'
                >
                    Запрос на восстановление
                </Button>
            </div>
        </form>
    )


    const RecoveryForm = () => (
        <form>
            <Stack gap={3}>
                <div className='h5'>Установите новый пароль:</div>
                <FloatingLabel label="Придумайте пароль">
                    <Form.Control 
                        type="password" 
                        placeholder="Придумайте новый пароль"
                        value={recoveryPassword}
                        onChange={(e)=> setRecoveryPassword(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
                {
                    !isPasswordStrong ? <div className='text-warning'>Пароль слабоват, используйте символы в разном регистре и цифры, не менн 6 символов</div> : null
                }
               
                
                <FloatingLabel label="И введите его повторно">
                    <Form.Control 
                        type="password" 
                        placeholder="Придумайте новый пароль"
                        value={recoveryPasswordRepeat}
                        onChange={(e)=> setRecoveryPasswordRepeat(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
                {
                    !isPasswordEqual ? <div className='text-danger'>Пароли не совпадают</div> : null
                }

            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Link className="link text-secondary text-decoration-none ms-2" to="/">&larr; Назад</Link>
                <Button
                    variant="primary"
                    onClick={ changePassword }
                    disabled={isLoading}
                    className='w-50'
                >
                    Сохранить новый пароль
                </Button>
            </div>
        </form>
    )

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header text-center"><img src="/assets/img/logo.black.priemka-pro.ru.png" class="w-50 py-3" alt="Приёмка Про"/></div>
                                    <div className="card-body mb-4">
                                        <Routes>
                                            <Route path='/recovery' 	element={ recoveryToken ? RecoveryForm() : RecoveryRequest() } />
                                            <Route path='/signup' 		element={ SignupForm() } />
                                            <Route path='*' 			element={ LoginForm() } />
                                        </Routes>
                                    </div>
                                    <div className="card-footer text-center py-3 small">
                                        <Routes>
                                            <Route path='/signup' 		element={ <>Уже есть аккаунт?<Link className="link text-decoration-none ms-2" to="/">Войти</Link></> } />
                                            <Route path='*' 			element={ Config.FeatureToggles.signupAvaliable && <Link className="link text-decoration-none ms-2" to="/signup">Регистрация нового аккаунта</Link> } />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}